import './App.css';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import { Routes,Route } from 'react-router-dom';
import Contact from './pages/contact/Contact';
import Services from './components/Services';
import ScrollToTop from './components/ScrollToTop';
function App() {
  return (
   <>
   <ScrollToTop/>
   <div >
    <div className='fixed w-full top-0 z-[9999] '>
    <Navbar/>

    </div>
    <div className='absolute top-0 z-[-10]'>
    <Routes>
    <Route path='/' element={<Home/>} />
    <Route path='contact' element={<Contact/>}></Route>
    <Route path='services' element={<Services/>}></Route>
   </Routes>
   <Footer/>

    </div>
   

   </div>
   
   </>
  );
}
export default App;