import React from 'react'
import { FaInstagram,FaWhatsapp} from "react-icons/fa";
import { CiMail } from "react-icons/ci";
const Footer = () => {
  return (
    <>
    <div className='grid grid-cols-1 md:grid-cols-4 p-5 gap-10 text-center py-16 text-white' >
        <div className=''>
          <h1 className='text-2xl font-serif text-green hover:underline cursor-pointer underline-offset-2'>Bideotech.com</h1>
          <p className='text-sm px-10 p-3  font-thin leading-5'>We manages a group of companies which are engaged in a wide range of media and entertainment related businesses, including creative and post-production services,</p>
        </div>
        <div className=''>
          <h1 className='text-2xl font-serif text-green hover:underline cursor-pointer underline-offset-2'>Services</h1>
          <ul className='text-sm font-thin leading-10'>
            <a href='#services'> <li className='hover:text-darkgreen  underline-offset-2 cursor-pointer'>DISTRIBUTION SERVICES</li></a>
            <a href='#services'> <li className='hover:text-darkgreen  underline-offset-2 cursor-pointer'>AUDIO SERVICES</li></a>
            <a href='#services'> <li className='hover:text-darkgreen  underline-offset-2 cursor-pointer'>LOCALIZATION SERVICES</li></a>
          </ul>
        </div>
        <div className=''>
        <h1 className='text-2xl font-serif text-green  hover:underline cursor-pointer underline-offset-2'>Contact Us</h1>
          <ul className='text-sm  font-thin leading-10'>
            <li className='hover:text-darkgreen  underline-offset-2 cursor-pointer' >REQUEST FOR SERVICES</li>
            <li className='hover:text-darkgreen  underline-offset-2 cursor-pointer'>JOIN US</li>
            <li className='hover:text-darkgreen  underline-offset-2 cursor-pointer'>CALL US</li>
          </ul>
        </div>
        <div className=''>
        <h1 className='text-2xl font-serif text-green hover:underline cursor-pointer underline-offset-2'>Connect with Us</h1>
        <div className='flex justify-center gap-5 p-5'>
        <FaInstagram size={30} className='cursor-pointer' />
        <FaWhatsapp size={30} className='cursor-pointer'/>
        <CiMail size={30} className='cursor-pointer'/>
        </div>
        </div>
        

    </div>
    <div className='text-center p-2 border-t text-white'>
      <p>Copyright © 2024 Bideotech.com</p>
    </div>
    </>
  )
}
export default Footer
