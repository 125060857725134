import {useState} from 'react'
import Typewriter from 'typewriter-effect';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    requirements: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log('fneiofjpoj')
      const response = await fetch('http://localhost:445/api/client/inquiries', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      console.log(response)
      
      if (response.ok) {
        // Form submitted successfully, you can handle success here
        console.log('Form submitted successfully');
      } else {
        // Handle errors here
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  return (
    <>
    <div className=''>
        <div className='h-96 relative w-full '>
            <img src='./media/img10.jpg' alt='no img ' className='h-full w-full object-cover'></img>
            <div className='h-full w-full  absolute top-0  bg-black/50 overflow-hidden'>
            <h1 className='text-2xl text-white md:text-6xl mt-32 md:mt-20 font-bold tracking-widest relative left-[35%]  '>
                <Typewriter
                  options={{
                    strings: ['Contact Us...', 'Call Us...', 'Meet Us...'],
                    autoStart: true,
                    loop: true,
                  }}
                ></Typewriter>
            </h1>
            </div>
        </div>
            <div className='w-full relative overflow-hidden z-[999] rounded-xl -mt-32 '>
              <div className='w-[90%] md:w-1/2   grid grid-cols-1 md:grid-cols-2 gap-5 mx-auto p-5'>
                <div className=' grid grid-row-3 gap-5 '>
                  <div className='border h-32 shadow-xl font-semibold  text-black flex flex-col justify-center items-center gap-2 bg-white rounded-md'>
                      {/* <p className='text-black'>Talk to Us Directly at</p> */}
                      <p className='text-black'>+919191991919</p>
                      <button className=' bg-black text-white p-1 px-5'>Call Now</button>
                  </div>
                  <div className=' border h-32 shadow-xl font-semibold  text-black flex flex-col justify-center items-center gap-2 bg-white rounded-md'>
                    <p className='text-black'>Schedule a  Meeting </p>
                    <button className=' bg-black text-white p-1 px-5'>Book Now</button>
                  </div>
                  <div className=' border h-32 shadow-xl font-semibold  text-black flex flex-col justify-center items-center gap-2 bg-white rounded-md'>
                  <p className='text-black'>Connect with us Socially</p>
                    <div className=' flex gap-5 text-3xl'>
                    <img className='w-8 h-8 cursor-pointer' src='./media/insta.jpeg' alt=''></img>
                    <img className='w-8 h-8 cursor-pointer' src='./media/whatsapp.jpg' alt=''></img>
                    <img className='w-8 h-8 cursor-pointer' src='./media/upwork.png' alt=''></img>
                    <img className='w-8 h-8 cursor-pointer' src='./media/gmail.png' alt=''></img>
                    </div>
                  </div>
                </div>
                <form  className='flex flex-col justify-between gap-5 mx-auto w-full px-5 p-2 bg-white rounded-md'
                onSubmit={handleSubmit}>
                  <p className='text-center text-sm font-semibold tracking-widest -mb-3 '>Have Any Inquiry ?</p>
                  <input type='text' placeholder='Your Name' className='bg-transparent  border  p-3 outline-none'
                  name='name'
                  value={formData.name}
                  onChange={handleChange}/>
                  <input type='number' placeholder='Your Number' className='bg-transparent  border  p-3 outline-none'
                   name='number'
                   value={formData.number}
                   onChange={handleChange}/>
                  <input type='email' placeholder='Your Email' className='bg-transparent  border  p-3 outline-none'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}/>
                  <textarea type='' placeholder='Your Requirements' className='bg-transparent  border  p-3 outline-none'
                  name='requirements'
                  value={formData.requirements}
                  onChange={handleChange}/>
                  <button type='submit' className='text-white border p-2 bg-black'>
                    Submit
                  </button>
                </form>  
              </div>    
            </div>
        <div >
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d8404.500118814509!2d77.30752342034882!3d28.638845334148428!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1708518984523!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='w-full h-96 p-5'></iframe>
        </div>
    </div>
    </>
  )
}

export default Contact
