import React,{useState} from 'react'
import Services from '../components/Services'
import Typewriter from 'typewriter-effect';
const Home = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const previousContent=[
    'Dubbing and Subtitling',
    'Artwork Creation',
    'OTT Packaging',
    'Audio and Video',
    'Promo and Trailer Creation',
    'Content Editing',
    'Metadata Creation',
    'Compliance Adherence ',

  ]

  const content = [
    'Break Down Language Barriers and Expand Your Reach',
    'Ignite Your Imagination with Stunning Visuals',
    'Launch Your Content into the Digital Stratosphere',
    'Ensuring your audio and visuals are crisp, clear, and captivating',
    'Ignite Excitement and Generate Buzz',
    'Crafting Compelling Narratives, Frame by Frame',
    'Unlock the Power of Discoverability and Engagement',
    'Navigate Local Laws with Confidence'
  ];

  const handleMouseOver = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredIndex(null);
  };
  return (
    <>
    <div className='h-60  md:h-[90vh] w-full  text-white '>
      <video src='./media/video1.mp4' autoPlay loop muted className='w-full h-full object-cover'></video>

    </div>
    <div className='px-2 mt-10 md:px-10 w-full gap-10 grid grid-cols-1 md:grid-cols-4 text-white'>
        {previousContent.map((item, index) => (
          <div
            key={index}
            className={`h-60 relative flex justify-center items-center overflow-hidden  ${index === hoveredIndex ? 'cursor-pointer ' : 'cursor-auto'}`}
            onMouseOver={() => handleMouseOver(index)}
            onMouseOut={handleMouseOut}
          >
            <h1 className={`absolute top-[45%] text-xl font-bold text-center h-full w-full  ${index === hoveredIndex ? 'bg-black/80 ' : 'bg-black/50'}`}>
            {index === hoveredIndex ? content[index] : item}
          </h1>
            <img src={`./media/img${index + 18}.jpg`} alt='no img' className='w-full h-full object-cover' />
          </div>
        ))}
      </div>

    

  


    <div className='grid grid-cols-1 md:grid-cols-2 p-2 mt-10  text-white '>
      <div className='overflow-hidden  w-[70%] mx-auto  h-60 md:h-80 '>
        <img src='./media/img26.jpg' alt='' className='h-full w-full object-cover hover:scale-125 transition-all duration-500 delay-100'></img>
      </div>
      <div className='p-2 md:pt-5 md:px-10 md:pr-20 md:leading-8 '>
        <h1 className='text-xl md:text-4xl tracking-widest text-center text-darkgreen font-serif font-bold'>Who we are</h1>
        <p className='mt-5 '>We are a media services company who builds and executes consumer obsessed digital solutions for our business customers. We specialize in the comprehensive management and delivery of diverse content types, encompassing films, series, fiction, documentaries, animation, and institutional programs
        </p>
      </div>
    </div>


  
  <div className='parallax-content flex flex-col justify-center items-center my-5 h-96 gap-5 text-white' style={{
    background: `linear-gradient(11deg, rgba(83,12,87,1) 19%, rgba(9,19,121,1) 47%, rgba(21,179,137,1) 100%)`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }}>
    <h1 className='text-3xl md:text-8xl font-bold tracking-wide'>LET'S TALK</h1>
    <p className='text-md tracking-widest font-thin text-center'>Discover compliance services with BIDEOTECH</p>
    <button className='font-bold tracking-widest p-3 border rounded-md hover:bg-black hover:border-transparent'>Contact BIDEOTECH</button>
  </div>             
    </>
  )
}
export default Home