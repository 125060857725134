import { useState } from "react";
import Modal from "./Modal";

const Services = () => {


  return (
    <>
      <div className=" bg-white  overflow-auto">
        <div className="h-32 flex items-end justify-center">
        <h1 className="text-xl md:text-3xl text-center font-semibold">Welcome to BIDEOTECH's World of Creativity!</h1>
        </div>
        <h1 className="text-sm my-3 font-normal text-center px-10">Unlock the Full Potential of Your Content with Our Suite of Video Editing Services</h1>
        <h1 className="text-md my-3 px-10 md:px-24 font-normal text-center">At BIDEOTECH, we believe that every frame has a story to tell. Our team of passionate storytellers and seasoned professionals is dedicated to transforming your raw footage into captivating masterpieces that leave a lasting impact on your audience. </h1>
        <div className="py-10">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-16 px-2 md:px-16">
          <div className="shadow-md">
            <img src="./media/img21.jpg" className="  object-cover rounded-md h-52 w-full "></img>
            <h1 className="text-center font-semibold text-md">Audio Video Quality Check</h1>
            <p className="text-xs text-center leading-7">
            At BIDEOTECH, we understand the power of pristine audio and mesmerizing visuals in capturing the hearts and minds of your viewers. Our meticulous Audio Video Quality Check ensures that every element of your content resonates with clarity, brilliance, and emotion, transporting your audience into a world of immersive storytelling.
            </p>

          </div>

          <div className="shadow-md">
            <img src="./media/img23.jpg" className="  object-cover rounded-md h-52 w-full "></img>
            <h1 className="text-center font-semibold text-md">Content Editing</h1>
            <p className="text-xs text-center leading-7">
            Elevate your storytelling with BIDEOTECH's Content Editing services. Our team of skilled editors works tirelessly to refine your footage into a seamless narrative that captivates, inspires, and resonates with your audience. From pacing and structure to tone and emotion, we meticulously shape each frame to bring your vision to life in stunning detail.
            </p>

          </div>

          <div className="shadow-md">
            <img src="./media/img20.jpg" className="  object-cover rounded-md h-52 w-full "></img>
            <h1 className="text-center font-semibold text-md">OTT Packaging</h1>
            <p className="text-xs text-center leading-7">
            Ready to conquer the world of digital streaming? BIDEOTECH's OTT Packaging services are your ticket to success. Our experts specialize in preparing your content for distribution across over-the-top (OTT) platforms, ensuring maximum visibility, engagement, and monetization opportunities.
            </p>

          </div>

          <div className="shadow-md">
            <img src="./media/img24.jpg" className="  object-cover rounded-md h-52 w-full "></img>
            <h1 className="text-center font-semibold text-md">Metadata Creation</h1>
            <p className="text-xs text-center leading-7">
            In today's crowded digital landscape, metadata is king. BIDEOTECH's Metadata Creation services empower your content to stand out from the crowd, with meticulously crafted tags, descriptions, and keywords that optimize discoverability, drive engagement, and connect with your target audience on a deeper level.
            </p>

          </div>

          <div className="shadow-md">
            <img src="./media/img26.jpg" className="  object-cover rounded-md h-52 w-full "></img>
            <h1 className="text-center font-semibold text-md">Artwork Creation</h1>
            <p className="text-xs text-center leading-7">
            Transform your content into a work of art with BIDEOTECH's Artwork Creation services. From eye-catching thumbnails to breathtaking cover art, our team of designers specializes in crafting visuals that capture attention, evoke emotion, and inspire action. 
            </p>

          </div>

          <div className="shadow-md">
            <img src="./media/img27.jpg" className="  object-cover rounded-md h-52 w-full "></img>
            <h1 className="text-center font-semibold text-md">Compliance Adherence</h1>
            <p className="text-xs text-center leading-7">
            Stay ahead of the curve and ensure your content meets all necessary compliance standards with BIDEOTECH's Compliance Adherence services. Our team of legal experts keeps a pulse on ever-evolving regulations, ensuring your content remains in full compliance with local laws and regulations, while minimizing risk and maximizing impact. 
            </p>

          </div>

          <div className="shadow-md">
            <img src="./media/img22.jpg" className="  object-cover rounded-md h-52 w-full "></img>
            <h1 className="text-center font-semibold text-md">Promo or Trailer Creation</h1>
            <p className="text-xs text-center leading-7">
            Ready to make a splash with your next project? BIDEOTECH's Promo or Trailer Creation services are your secret weapon for building anticipation, generating excitement, and driving engagement. From teaser trailers to full-blown promos, our team of creatives specializes in crafting dynamic, unforgettable content that leaves your audience hungry for more. 
            </p>

          </div>

          <div className="shadow-md">
            <img src="./media/img18.jpg" className="  object-cover rounded-md h-52 w-full "></img>
            <h1 className="text-center font-semibold text-md">Dubbing and Subtitling</h1>
            <p className="text-xs text-center leading-7">
            Make your content accessible to audiences around the globe with BIDEOTECH's Subtitle Creation services. Our team of linguists and translators specializes in crafting accurate, culturally relevant subtitles in multiple languages, ensuring your message resonates with every viewer, no matter where they are. 
            </p>

          </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default Services;