import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'flowbite-react';
import { FaBarsStaggered } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
const Navbar = () => {
  const [scrolling, setScrolling] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const openNav = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setScrolling(scrollTop > 100);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={`hidden md:grid grid-cols-3 z-[9999] w-full p-2 sticky top-0  text-black ${
          scrolling ? 'bg-white text-black' : 'bg-transparent text-black'
        } font-bold z-[99999]`}
      >
        <div className=''>

        </div>
        <div className=' flex gap-5 col-span-2 pl-16'>
        
        <div className='flex justify-center items-center  ml-10 '>
          <div className='h-16 bg-black flex'>
            <img src='./media/logo10.png' alt='' className='w-24'></img>
            <div className='border border-black p-2 text-center'>
              <p className={`text-white text-xl font-serif font-semibold`}>
                BIDEOTECH
              </p>
              <p className={`text-white text-xs`}>Bringing Vision to life</p>
            </div>
          </div>
        </div>
        <div className='flex justify-end gap-10 items-center '>
          <Link to='/'>
            <p>Home</p>
          </Link>
          <Dropdown
            label='What we do'
            dismissOnClick={false}
            style={{
              backgroundColor: 'transparent',
              'fontWeight':"800",
              color: scrolling ? 'black' : 'black',
              outline: 'none',
              border: 'none',
            }}
            className='dropdown'
          >
            <Link to='/services'>
              <Dropdown.Item>Services</Dropdown.Item>
              <Dropdown.Item>About Us</Dropdown.Item>
            </Link>
          </Dropdown>
        </div>
        <div className='flex justify-start gap-10 items-center '>
          <Link to='contact'>
            <p className='hidden md:block'>Contact Us</p>
          </Link>
        </div>
        </div>
      </div>





      <div className='block md:hidden sticky top-0 bg-white'>
        <div className='flex justify-between p-3'>
       <img src='./media/logo11.png' className='w-20 '></img>
   
       {isOpen?<ImCross onClick={openNav}/>:<FaBarsStaggered onClick={openNav}/>}

        </div>
        <div className={`bg-white border items-center justify-around font-bold overflow-hidden flex flex-col text-black text-xl transition-all duration-500 ${isOpen ? 'h-52' : 'h-0'}`}>
          <Link to='/' onClick={openNav}>Home</Link>
          <Link to='services' onClick={openNav}>Services</Link>
          <Link to='contact' onClick={openNav}>Contact</Link>



        </div>

      </div>

     
    </>
  );
};

export default Navbar;
