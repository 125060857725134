//to start the page from top when user navigates to new page
import { useLocation } from "react-router";
import React, { useLayoutEffect } from 'react';
function ScrollToTop() {
    const { pathname } = useLocation();
  
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }
export default ScrollToTop;